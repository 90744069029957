<template>
  <div>
    <div>
      <!-- input search -->
      <b-button
        class="mr-right-10"
        v-ripple.400="'rgba(13, 110, 253, 0.15)'"
        v-b-modal.modal-1
        variant="outline-primary"
      >
      <feather-icon
        icon="EyeOffIcon"
        class="mr-50"
      />
      <span class="align-middle">Columns</span>
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        v-b-modal.sync-rf
      >
      <feather-icon
        icon="RefreshCwIcon"
        class="mr-50"
      />
      <span class="align-middle">Sync with RF</span>
      </b-button>
      <b-modal
        id="sync-rf"
        centered
        title="Warning"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="Cancel"
        cancel
        @ok="handleRfOk"
      >
      <b-card-text class="font-weight-bolder">
        To continue, please read the following info:
      </b-card-text>
      <b-card-text class="text-warning">
        This action is NOT reversible.
      </b-card-text>
      <b-card-text>
        Your request will be received and the current information will be automatically and permanently updated within 30 minutes. 
      </b-card-text>
      <b-card-text>
        Action will take place in the background, you will not be notified when it finishes.
      </b-card-text>
      <b-card-text class="mt-2">
        Are you sure you want to continue?
      </b-card-text>
      </b-modal>
      <b-modal
        id="modal-1"
        centered
        title="Hide Columns"
        ok-title="Ok"
        ok-only
        @ok="saveColumns"
        size="lg"
      >
        <b-card-text>
          <b-container>
            <b-row>
              <b-card-text class="font-weight-bolder mb-1 ml-12">
                Hide or unhide columns in your table to show just the data that you need to see.
              </b-card-text>
              <b-col cols="12">
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    style="column-count: 4"
                    v-model="selectedColumns"
                    :options="myColumns"
                    :aria-describedby="ariaDescribedby"
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-text>
      </b-modal>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-checkbox @input="onSwitchChange()" v-model="checked" name="check-button" switch style="min-width: 150px; margin-top: 10px;">
              <span style="font-size: 14px;">Hide Rhinos</span>
            </b-form-checkbox>
            <div :style="query !== '' ? 'min-width: 250px;': 'min-width: 110px;'">
              <b-button
                class="padding-11 mr-50"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-secondary"
                @click="clearData()"
                v-if="query !== ''"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  class="mr-right-10"
                />
                <span class="align-middle">Clear</span>
              </b-button>
              <b-button
              v-if="hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'])"
                class="padding-11"
                v-ripple.400="'rgba(13, 110, 253, 0.15)'"
                variant="flat-primary"
                :style="query !== '' ? 'background-color: rgba(13, 110, 253, 0.2); !important;' : ''"
                v-b-modal.modal-filters
              >
                <feather-icon
                  icon="FilterIcon"
                  class="mr-right-10"
                />
                <span class="align-middle">Filters <span v-if="myFilter.length > 0">({{ myFilter.length }})</span></span>
              </b-button>
            </div>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div class="margin-left-10">
          <b-button
            v-if="hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'])"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-secondary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Download'"
            @click="download()"
          >
            <feather-icon size="1.5x" icon="DownloadIcon" />
          </b-button>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        ref="financeTable"
        v-if="columns.length > 0 && show"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm 
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :totalRows="total"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :isLoading.sync="isLoading"
      >
        <template slot="table-column" slot-scope="props">
          <span 
            class="text-nowrap">
            {{props.column.label}}
          </span>
        </template>
        <template
          slot="column-filter"
          slot-scope="props"
        >
          <input v-if="props.column.type === 'text'"
            type="text"
            class="vgt-input"
            @keyup.enter="props.updateFilters(props.column, $event.target.value)"
            @input="props.updateFilters(props.column, $event.target.value)" />
          <v-select
            v-else-if="props.column.type === 'select'"
            v-model="props.column.selected"
            dir="ltr"
            label="title"
            :options="props.column.options"
            @input="props.column.selected === null ? props.updateFilters(props.column, '') : props.updateFilters(props.column, $event.title)"
            class="select-size-sm"
          />
          <flat-pickr 
            v-else-if="props.column.type === 'date'"
            :config="{
              mode: 'range'
            }"
            class="vgt-input"
            v-model="props.column.date"
            @input="props.updateFilters(props.column, $event)"
            ></flat-pickr>
          <div v-else-if="props.column.type === 'number' && props.column.model === 1" class="d-flex">
            <input
              type="number"
              class="vgt-input min-input-55"
              v-model="props.column.value[0]"
              @keyup.enter="props.updateFilters(props.column, props.column.value)"
              @input="props.updateFilters(props.column, props.column.value)" />
            <span class="margin-10">to</span>
            <input
              type="number"
              class="vgt-input min-input-55"
              v-model="props.column.value[1]"
              @keyup.enter="props.updateFilters(props.column, props.column.value)"
              @input="props.updateFilters(props.column, props.column.value)" />
          </div>
          <div v-else-if="props.column.type === 'number'" class="d-flex">
            <input
              type="number"
              class="vgt-input min-input-55"
              v-model="props.column.value"
              @keyup.enter="props.updateFilters(props.column, props.column.value)"
              @input="props.updateFilters(props.column, props.column.value)" />
          </div>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field === 'candidate'"
            :class="'text-nowrap'"
          >
            <a :href="props.row.link" target="_blank" :class="(props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              <span :class="'text-nowrap '">{{ props.row.candidate }}</span>
            </a>
          </div>
          <span v-else-if="props.column.field === 'status.description'"  class="text-nowrap">
            <span :class="(props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              <b-badge :variant="statusVariant(props.row.status.description)">
                {{ props.row.status.description }}
              </b-badge>
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'disqualification_reason'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'process_stage'" class="text-nowrap">
            <span v-if="props.row.process_stage">{{ props.row.process_stage }}%</span>
            <span v-else :class="(props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')"></span>
            <b-progress
              v-if="props.row.process_stage"
              :style="props.row.status.description === 'Disqualified' && props.row.process_stage < 1 ? 'background-color: #ea5455 !important;' : ''"
              v-model="props.row.process_stage"
              :variant="processStageVariant(props.row.process_stage, props.row.status.description)"
              max="100"
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Edit Info'"
                v-b-modal.edit-btn
                @click="edit(props.row)"
                aria-pressed="false"
                :disabled="!hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter']) ? true : props.row.status.description === 'No allocation' ? true : false"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                :disabled="!hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'])"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Add Note'"
                aria-pressed="false"
                v-b-modal.edit-note-btn
                @click="editNote(props.row)"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                />
              </b-button>
              <b-button
                v-if="props.row.status.description === 'Hired' || props.row.status.description === 'Internalized' || props.row.status.description === 'Project end' || props.row.status.description === 'No allocation'"
                :disabled="!hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'])"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top="'Add Project Revenue'"
                v-b-modal.edit-revenue-btn
                @click="editRevenue(props.row)"
                aria-pressed="false"
              >
                <feather-icon
                  icon="TrendingUpIcon"
                />
              </b-button>
            </span>
          </span>
          <span :class="'text-nowrap text-info'" v-else-if="props.column.field === 'project_revenue'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ cleanZero(String(props.formattedRow[props.column.field])) | currency }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'reason.description'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span :class="'text-nowrap'" v-else-if="props.column.field === 'roi'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ cleanZero(String(props.formattedRow[props.column.field])) | currency }}
            </span>
          </span>
          <span :class="'text-nowrap'" v-else-if="props.column.field === 'roi_percentage'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ cleanZero(String(props.formattedRow[props.column.field])) + (cleanZero(String(props.formattedRow[props.column.field])) > 0 ? ' %' : '') }}
            </span>
          </span>
          <span :class="'text-nowrap'" v-else-if="props.column.field === 'pricelist_roi'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ cleanZero(String(props.formattedRow[props.column.field])) | currency }}
            </span>
          </span>
          <span :class="'text-nowrap'" v-else-if="props.column.field === 'pricelist_roi_percentage'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ cleanZero(String(props.formattedRow[props.column.field])) + (cleanZero(String(props.formattedRow[props.column.field])) > 0 ? ' %' : '') }} 
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'fee_rate'" >
            <span v-if="props.formattedRow['fee_type'] === '€'" :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : (props.row.data && props.row.data.fee_rate ? 'bg-table-secondary ' : ' ')">
              {{ cleanZero(String(props.formattedRow[props.column.field])) | currency }}
            </span>
            <span v-else :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : (props.row.data && props.row.data.fee_rate ? 'bg-table-secondary' : '')">
              {{ cleanZero(String(props.formattedRow[props.column.field])) }} {{ props.formattedRow['fee_type'] }}
            </span>
          </span>
          <span :class="'text-nowrap ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? '' : 'height-30')" v-else-if="props.column.field === 'note'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              <span v-html="(props.formattedRow['note'] ? props.formattedRow['note'].substring(0, 40) : '') + (props.formattedRow['note'] && props.formattedRow['note'].length > 40 ? '...' : '')"></span>
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'recruiter.name'" >
            <span :class="(props.row.data && props.row.data.recruiter ? 'bg-table-secondary ' : ' ') + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'manager.name'" >
            <span :class="(props.row.data && props.row.data.manager ? 'bg-table-secondary ' : ' ') + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'customer.name'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              <span v-html="(props.formattedRow['customer.name'] ? props.formattedRow['customer.name'].substring(0, 30) : '') + (props.formattedRow['customer.name'] && props.formattedRow['customer.name'].length > 30 ? '...' : '')"></span>
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'current_stage.description'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <!-- <span class="text-nowrap" v-else-if="props.column.field === 'next_stage.description'" >
            <span :class="props.row.status.description === 'No allocation' ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span> -->
          <span class="text-nowrap" v-else-if="props.column.field === 'department.description'" >
            <span :class="props.row.data && props.row.data.department ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'job.name'" >
            <span :class="props.row.data && props.row.data.job ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'sent_date'" >
            <span :class="(props.row.data && props.row.data.sent_date ? 'bg-table-secondary  ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '') + (props.row.data && props.row.data.color_date ? 'text-primary' : ''))">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'last_update'" >
            <span :class="props.row.data && props.row.data.last_update ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'start_date'" >
            <span :class="props.row.data && props.row.data.start_date ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'end_date'" >
            <span :class="props.row.data && props.row.data.end_date ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span :class="'text-nowrap ' + ( props.formattedRow[props.column.field] > 15 ? 'text-danger' : '')" v-else-if="props.column.field === 'days_in_stage'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props | stageFilterInStage }}
            </span>
          </span>
          <span :class="'text-nowrap ' + (props.formattedRow[props.column.field] > 30 ? 'text-danger ' : (props.formattedRow[props.column.field] > 21 ? 'text-warning' : ''))" v-else-if="props.column.field === 'days_in_process'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props | stageFilterInProcess }}
            </span>
          </span>
          <span :class="'text-nowrap ' + (props.column.field === 'revenue' && props.formattedRow['status.description'] === 'Hired' ?  'text-success' : '')" v-else-if="props.column.field === 'salary'" >
            <span :class="props.row.data && props.row.data.salary ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ cleanZero(String(props.formattedRow[props.column.field]).replace(',', '')) | currency }}
            </span>
          </span>
          <span :class="'text-nowrap ' + (props.column.field === 'revenue' && (props.formattedRow['status.description'] === 'Hired' || props.formattedRow['status.description'] === 'Internalized' || props.formattedRow['status.description'] === 'Project end') ?  'text-success' : '')" v-else-if="props.column.field === 'revenue'" >
            <span :class="props.row.data && props.row.data.revenue ? 'bg-table-secondary ' : ' ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')">
              {{ cleanZero(String(props.formattedRow[props.column.field]).replace(',', '')) | currency }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'next_stage.description'">
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] && props.formattedRow[props.column.field].length > 30 ? props.formattedRow[props.column.field].substring(0, 30) + '...'  : props.formattedRow[props.column.field]}}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'invoice_number'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span class="text-nowrap" v-else-if="props.column.field === 'invoice_date'" >
            <span :class="props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : ''">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span :class="'text-nowrap ' + (props.row.status.description === 'No allocation' && !props.row.end_date ? 'bg-table-yellow' : '')" v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <b-card
            bg-variant="transparent"
            class="shadow-none card-border no-rounded-border"
          >
            <b-card-text>
              <b-row>
                <b-col
                  v-if="myFilter.length > 0"
                  xl="2"
                  sm="6">
                  <b-row>
                    <b-col class="font-small-5">
                      Filters
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-badge v-for="item in myFilter" :key="item" variant="light-dark" class="limit-size">
                        {{ item }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  xl="2"
                  sm="6"
                  class="mb-2 mb-xl-0"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="24"
                          icon="ListIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0 text-primary">
                        {{ total }}
                      </h4>
                      <b-card-text class="font-small-5 mb-0">
                        Total
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-success"
                      >
                        <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0 text-success">
                        {{ totalRevenue | toCurrency }}
                      </h4>
                      <b-card-text class="font-small-5 mb-0">
                        Revenue
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-info"
                      >
                        <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0 text-info">
                        {{ totalPr | toCurrency }}
                      </h4>
                      <b-card-text class="font-small-5 mb-0">
                        Project Revenue
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  xl="2"
                  sm="6"
                  class="mb-2 mb-xl-0"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-secondary"
                      >
                        <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0 text-secondary">
                        {{ totalRoi | toCurrency }}
                      </h4>
                      <b-card-text class="font-small-5 mb-0">
                        ROI
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  xl="2"
                  sm="6"
                  class="mb-2 mb-xl-0"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-dark"
                      >
                        <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0 text-dark">
                        {{ totalPricelist | toCurrency }}
                      </h4>
                      <b-card-text class="font-small-5 mb-0">
                        Pricelist ROI
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="edit-btn"
      ref="edit-btn"
      title="Edit Info"
      ok-title="Save"
      size="lg"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-disabled="isValid()"
    >
      <form
        ref="form"
        v-if="Object.entries(detail).length > 0"
        @submit.stop.prevent="handleOK"
      >
        <b-row>
          <b-col cols="6" v-if="detail.recruiter">
            <b-form-group
              label="Recruiter"
              label-for="name-input"
              invalid-feedback="Recruiter is required"
              :description="Object.keys(detail.data).length === 0  || !detail.data['recruiter'] ? '' : 'Last update by ' + detail.data.recruiter + ' | ' + detail.data.recruiter_updated_at + '.'"
            >
              <v-select
                v-model="detail.recruiter.name"
                dir="ltr"
                label="title"
                :options="recruiters"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              v-if="detail.manager"
              label="Business/account manager"
              label-for="name-input"
              invalid-feedback="Business/account manager is required"
              :description="Object.keys(detail.data).length === 0  || !detail.data['manager'] ? '' : 'Last update by ' + detail.data.manager + ' | ' + detail.data.manager_updated_at + '.'"
            >
              <v-select
                v-model="detail.manager.name"
                dir="ltr"
                label="title"
                :options="managers"
              />
            </b-form-group>
            <b-form-group
              v-else
              label="Business/account manager"
              label-for="name-input"
              invalid-feedback="Business/account manager is required"
              :description="Object.keys(detail.data).length === 0  || !detail.data['manager'] ? '' : 'Last update by ' + detail.data.manager + ' | ' + detail.data.manager_updated_at + '.'"
            >
              <v-select
                v-model="detail.manager"
                dir="ltr"
                label="title"
                :options="managers"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="ID"
              label-for="id"
            >
              <b-form-input
                id="id"
                disabled
                v-model="detail.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-for="type"
              v-if="departments.length >0"
              :description="Object.keys(detail.data).length === 0  || !detail.data['department'] ? '' : 'Last update by ' + detail.data.department + ' | ' + detail.data.department_updated_at + '.'"
            >
             <v-select
                v-model="detail.department.description"
                dir="ltr"
                label="description"
                :options="departments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Candidate"
              label-for="candidate"
            >
              <b-form-input
                id="candidate"
                disabled
                v-model="detail.candidate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Client"
              label-for="client"
            >
              <b-form-input
                id="client"
                disabled
                v-model="detail.customer.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Role"
              label-for="role"
              :description="Object.keys(detail.data).length === 0  || !detail.data['job'] ? '' : 'Last update by ' + detail.data.job + ' | ' + detail.data.job_updated_at + '.'"
            >
              <b-form-input
                id="role"
                disabled
                v-model="detail.job.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Sent date"
              label-for="sent_date"
              :description="Object.keys(detail.data).length === 0  || !detail.data['sent_date'] ? '' : 'Last update by ' + detail.data.sent_date + ' | ' + detail.data.sent_date_updated_at + '.'"
            >
              <b-form-datepicker
                id="sent_date"
                disabled
                v-model="detail.sent_date"
                reset-button
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Last update"
              label-for="last_update"
              :description="Object.keys(detail.data).length === 0  || !detail.data['last_update'] ? '' : 'Last update by ' + detail.data.last_update + ' | ' + detail.data.last_update_updated_at + '.'"
            >
              <b-form-datepicker
                id="last_update"
                disabled
                v-model="detail.last_update"
                reset-button
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="detail.status">
            <b-form-group
              label="Status"
              label-for="status"
            >
              <b-form-input
                id="status"
                disabled
                v-model="detail.status.description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="detail.current_stage">
            <b-form-group
              label="Current stage"
              label-for="current_stage"
            >
              <b-form-input
                id="current_stage"
                disabled
                v-model="detail.current_stage.description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="detail.next_stage">
            <b-form-group
              label="Next stage"
              label-for="next_stage"
            >
              <b-form-input
                id="next_stage"
                disabled
                v-model="detail.next_stage.description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="detail.process_stage">
            <b-form-group
              label="Process stage"
              label-for="process_stage"
            >
              <b-form-input
                id="process_stage"
                disabled
                v-model="detail.process_stage + '%'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Start date"
              label-for="start_date"
              :description="Object.keys(detail.data).length === 0  || !detail.data['start_date'] ? '' : 'Last update by ' + detail.data.start_date + ' | ' + detail.data.start_date_updated_at + '.'"
            >
              <b-form-datepicker
                id="start_date"
                v-model="detail.start_date"
                reset-button
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="6">
            <b-form-group
              label="Project end date"
              label-for="end_date"
              :description="Object.keys(detail.data).length === 0  || !detail.data['end_date'] ? '' : 'Last update by ' + detail.data.end_date + ' | ' + detail.data.end_date_updated_at + '.'"
            >
              <b-form-datepicker
                id="end_date"
                v-model="detail.end_date"
                reset-button
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="6">
            <b-form-group
              label="Salary"
              label-for="salary"
              :description="Object.keys(detail.data).length === 0  || !detail.data['salary'] ? '' : 'Last update by ' + detail.data.salary + ' | ' + detail.data.salary_updated_at + '.'"
            >
              <cleave
                id="salary"
                v-model="detail.salary"
                class="form-control"
                :raw="false"
                :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                  }"
                placeholder="10,000"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Fee type"
              label-for="fee_rate_type"
              :description="Object.keys(detail.data).length === 0  || !detail.data['fee_type'] ? '' : 'Last update by ' + detail.data.fee_type + ' | ' + detail.data.fee_type_updated_at + '.'"
              invalid-feedback="This field is required"
              :state="isValidForm()"
            >
              <b-form-input
                id="fee_rate_type"
                placeholder="%"
                v-model="detail.fee_type"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :description="Object.keys(detail.data).length === 0  || !detail.data['fee_rate'] ? '' : 'Last update by ' + detail.data.fee_rate + ' | ' + detail.data.fee_rate_updated_at + '.'"
              invalid-feedback="This field is required"
              :state="isValidForm()"
              >
              <label for="fee-rate">Fee/Rate</label>
              <b-input-group
                :append="detail.fee_type"
              >
                <b-form-input placeholder="20" v-model="detail.fee_rate" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Revenue"
              label-for="revenue"
              :description="Object.keys(detail.data).length === 0  || !detail.data['revenue'] ? '' : 'Last update by ' + detail.data.revenue + ' | ' + detail.data.revenue_updated_at + '.'"
            >
              <cleave
                id="revenue"
                v-model="detail.revenue"
                class="form-control"
                :raw="false"
                :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                  }"
                placeholder="50,000"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="6">
            <b-form-group
              label="Invoice number"
              label-for="invoice_number"
              :description="Object.keys(detail.data).length === 0  || !detail.data['invoice_number'] ? '' : 'Last update by ' + detail.data.invoice_number + ' | ' + detail.data.invoice_number_updated_at + '.'"
            >
              <b-form-input
                id="invoice_number"
                :value="detail.invoice_number"
                @change="v => detail.invoice_number = v"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col cols="6">
            <b-form-group
              label="Invoice date"
              label-for="invoice_date"
              :description="Object.keys(detail.data).length === 0  || !detail.data['invoice_date'] ? '' : 'Last update by ' + detail.data.invoice_date + ' | ' + detail.data.invoice_date_updated_at + '.'"
            >
              <b-form-datepicker
                id="invoice_date"
                v-model="detail.invoice_date"
                reset-button
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="6" v-if="detail.status.description === 'Disqualified'">
            <b-form-group
              class="mt-1"
              label-for="live"
            >
              <b-form-checkbox
                v-model="detail.live"
                checked="false"
                class="custom-control-success"
                name="check-button"
                switch
                inline
              >
              Activate candidate*
              </b-form-checkbox>
            </b-form-group>
            <span class="font-small-3">*This action allows the line to be updated according to the Recruiter Flow.</span>
          </b-col>
          <b-col cols="6" v-if="detail.status.description === 'Hired' && detail.department.description === 'META'">
            <b-form-group
              class="mt-1"
              label-for="no_allocation"
              :description="Object.keys(detail.data).length === 0  || !detail.data['no_allocation'] ? '' : 'Last update by ' + detail.data.no_allocation + ' | ' + detail.data.no_allocation_updated_at + '.'"
            >
              <b-form-checkbox
                checked="false"
                class="custom-control-success"
                name="check-button"
                switch
                inline
                v-model="detail.is_no_allocation"
              >
              Switch candidate status to "No allocation"*.
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              v-if="detail.is_no_allocation"
              label="Please select date*"
              label-for="last_update2"
            >
              <b-form-datepicker
                id="last_update2"
                v-model="detail.last_update_no_allocation"
                reset-button
              />
            </b-form-group>
            <span class="font-small-3">*This action creates a new candidate line with "No allocation" status.</span>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-modal
      id="edit-revenue-btn"
      ref="edit-revenue-btn"
      :title="(detailRevenue.project_revenue === '' || detailRevenue.project_revenue === null ? 'Add' : 'Edit') + ' Project Revenue'"
      ok-title="Save"
      size="lg"
      centered
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleRevenueOk"
      :ok-disabled="editTrigger || editTriggerSale || editTriggerOther || editTriggerComments"
      >
        <form
          ref="form2"
          @submit.stop.prevent="handleRevenueOk"
          v-if="Object.entries(detailRevenue).length > 0"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Candidate"
                label-for="candidate"
              >
                <b-form-input
                  id="candidate"
                  disabled
                  v-model="detailRevenue.candidate"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Status"
                label-for="name-input"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['status'] ? '' : 'Last update by ' + detailRevenue.data.status + ' | ' + detailRevenue.data.status_updated_at + '.'"
              >
                <v-select
                  v-model="detailRevenue.status"
                  dir="ltr"
                  label="description"
                  :options="statusOptions"
                  :selectable="disabledStatus"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Reason"
                label-for="name-input"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['reason'] ? '' : 'Last update by ' + detailRevenue.data.reason + ' | ' + detailRevenue.data.reason_updated_at + '.'"
              >
                <v-select
                  v-model="detailRevenue.reason"
                  dir="ltr"
                  label="description"
                  :options="reasonOptions"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Start date"
                label-for="start_date"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['start_date'] ? '' : 'Last update by ' + detailRevenue.data.start_date + ' | ' + detailRevenue.data.start_date_updated_at + '.'"
              >
              <b-form-datepicker
                id="start_date"
                v-model="detailRevenue.start_date"
                reset-button
                disabled
              />
            </b-form-group>
          </b-col>
            <b-col cols="6">
              <b-form-group
                label="Project end date"
                label-for="end_date"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['end_date'] ? '' : 'Last update by ' + detailRevenue.data.end_date + ' | ' + detailRevenue.data.end_date_updated_at + '.'"
              >
                <b-form-datepicker
                  id="end_date"
                  v-model="detailRevenue.end_date"
                  reset-button
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Invoice number"
                label-for="invoice_number"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['invoice_number'] ? '' : 'Last update by ' + detailRevenue.data.invoice_number + ' | ' + detailRevenue.data.invoice_number_updated_at + '.'"
              >
                <b-form-input
                  id="invoice_number"
                  :value="detailRevenue.invoice_number"
                  placeholder="Enter invoice number"
                  @change="v => detailRevenue.invoice_number = v"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Invoice date"
                label-for="invoice_date"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['invoice_date'] ? '' : 'Last update by ' + detailRevenue.data.invoice_date + ' | ' + detailRevenue.data.invoice_date_updated_at + '.'"
              >
                <b-form-datepicker
                  id="invoice_date"
                  v-model="detailRevenue.invoice_date"
                  reset-button
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Monthly revenue"
                label-for="revenue_rf"
              >
                <number-format
                  disabled
                  v-model="detailRevenue.revenue_rf"
                  :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Project revenue"
                label-for="project_revenue"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['project_revenue'] ? '' : 'Last update by ' + detailRevenue.data.project_revenue + ' | ' + detailRevenue.data.project_revenue_updated_at + '.'"
              >
                <number-format
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  v-model="detailRevenue.project_revenue"
                  :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: true, isInteger: false, vueVersion: 'v2'  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Cost"
                label-for="cost"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['cost'] ? '' : 'Last update by ' + detailRevenue.data.cost + ' | ' + detailRevenue.data.cost_updated_at + '.'"
              >
                <number-format
                  v-model="detailRevenue.cost"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="ROI"
                label-for="roi"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['roi'] ? '' : 'Last update by ' + detailRevenue.data.roi + ' | ' + detailRevenue.data.roi_updated_at + '.'"
              >
                <number-format
                  v-model="detailRevenue.roi"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="% ROI"
                label-for="roi_percentage"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['roi_percentage'] ? '' : 'Last update by ' + detailRevenue.data.roi_percentage + ' | ' + detailRevenue.data.roi_percentage_updated_at + '.'"
              >
                <number-format
                  v-model="detailRevenue.roi_percentage"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  :options="{ precision: 2, prefix: '', suffix: ' %', decimal: ',', thousand: '.', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Pricelist ROI"
                label-for="pricelist_roi"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['pricelist_roi'] ? '' : 'Last update by ' + detailRevenue.data.pricelist_roi + ' | ' + detailRevenue.data.pricelist_roi_updated_at + '.'"
              >
                <number-format
                  v-model="detailRevenue.pricelist_roi"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Pricelist % ROI"
                label-for="pricelist_roi_percentage"
                :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['pricelist_roi_percentage'] ? '' : 'Last update by ' + detailRevenue.data.pricelist_roi_percentage + ' | ' + detailRevenue.data.pricelist_roi_percentage_updated_at + '.'"
              >
                <number-format
                  v-model="detailRevenue.pricelist_roi_percentage"
                  :disabled="detailRevenue.department.description === 'IHTA' || detailRevenue.department.description === 'Perm' ? true : false"
                  :options="{ precision: 2, prefix: '', suffix: ' %', decimal: ',', thousand: '.', acceptNegative: true, isInteger: false  }"
                ></number-format>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card-title class="mt-10 card-border-no-bottom font-medium-1 d-flex align-items-center justify-content-between">
            <span class="align-middle ml-50">Commission Recruiter</span>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                v-if="editTrigger === false"
                v-b-tooltip.hover.top="'Edit'"
                @click="editTrigger = true"
                aria-pressed="false"
              >
                <feather-icon
                  icon="EditIcon"
                  size="1.3x"
                />
              </b-button>
              <b-button
                v-if="editTrigger === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                @click="closeCommissionRecruiter"
                aria-pressed="false"
              >
                Close
              </b-button>
              <b-button
                v-if="editTrigger === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="saveCommissionRecruiter"
                aria-pressed="false"
              >
                Save
              </b-button>
            </div>
          </b-card-title>
          <div class="card-border">
            <b-row v-for="(item, i) in commissionRecruiter" :key="item.id" class="mt-1">
              <b-col cols="6">
                <b-form-group
                  label="Recruiter"
                  label-for="number"
                >
                  <v-select
                    v-model="item.recruiter"
                    dir="ltr"
                    label="name"
                    placeholder="Select recruiter"
                    :options="recruiters"
                    :disabled="!editTrigger"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="item.recruiter" cols="2">
                <b-form-group
                  label="Commission"
                  label-for="commission"
                >
                  <number-format
                    v-model="item.commission"
                    @change="calcCommissionRecruiter(i, $event)"
                    :options="{ precision: 2, prefix: '', suffix: ' %', decimal: ',', thousand: '.', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTrigger"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col v-if="item.recruiter" cols="3">
                <b-form-group
                  label="Total"
                  label-for="total"
                >
                  <number-format
                    v-model="item.total"
                    :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTrigger"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="item.hasOwnProperty('id') && editTrigger"
                  :disabled="!editTrigger"
                  v-b-tooltip.hover.top="'Delete'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-secondary"
                  class="btn-icon mt-20"
                  @click="deleteCommissionRecruiter(item, i)"
                  aria-pressed="false"
                >
                <feather-icon
                  icon="Trash2Icon"
                  size="1.3x"
                />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="6">
              <b-button
                class="mr-right-10 mt-10"
                v-ripple.400="'rgba(13, 110, 253, 0.15)'"
                variant="outline-primary"
                @click="newSelectRecruiter"
                :disabled="!editTrigger"
              >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add commission recruiter</span>
              </b-button>
            </b-col>
            </b-row>
          </div>
          <b-card-title class="mt-10 card-border-no-bottom font-medium-1 d-flex align-items-center justify-content-between">
            <span class="align-middle ml-50">Commission Sales</span>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                v-if="editTriggerSale === false"
                v-b-tooltip.hover.top="'Edit'"
                @click="editTriggerSale = true"
                aria-pressed="false"
              >
                <feather-icon
                  icon="EditIcon"
                  size="1.3x"
                />
              </b-button>
              <b-button
                v-if="editTriggerSale === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                @click="closeCommissionSale"
                aria-pressed="false"
              >
                Close
              </b-button>
              <b-button
                v-if="editTriggerSale === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="saveCommissionSale"
                aria-pressed="false"
              >
                Save
              </b-button>
            </div>
          </b-card-title>
          <div class="card-border">
            <b-row v-for="(item, i) in commissionSale" :key="item.id" class="mt-1">
              <b-col cols="6">
                <b-form-group
                  label="Business/account manager"
                  label-for="number"
                >
                  <v-select
                    v-model="item.manager"
                    dir="ltr"
                    label="name"
                    placeholder="Select business/account manager"
                    :options="managers"
                    :disabled="!editTriggerSale"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="item.manager" cols="2">
                <b-form-group
                  label="Commission"
                  label-for="commission"
                >
                  <number-format
                    v-model="item.commission"
                    @change="calcCommissionSale(i, $event)"
                    :options="{ precision: 2, prefix: '', suffix: ' %', decimal: ',', thousand: '.', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTriggerSale"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col v-if="item.manager" cols="3">
                <b-form-group
                  label="Total"
                  label-for="total"
                >
                  <number-format
                    v-model="item.total"
                    :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTriggerSale"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="item.hasOwnProperty('id') && editTriggerSale"
                  :disabled="!editTriggerSale"
                  v-b-tooltip.hover.top="'Delete'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-secondary"
                  class="btn-icon mt-20"
                  @click="deleteCommissionSale(item, i)"
                  aria-pressed="false"
                >
                <feather-icon
                  icon="Trash2Icon"
                  size="1.3x"
                />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="6">
              <b-button
                class="mr-right-10 mt-10"
                v-ripple.400="'rgba(13, 110, 253, 0.15)'"
                variant="outline-primary"
                @click="newSelectSale"
                :disabled="!editTriggerSale"
              >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add commission sale</span>
              </b-button>
            </b-col>
            </b-row>
          </div>
          <b-card-title class="mt-10 card-border-no-bottom font-medium-1 d-flex align-items-center justify-content-between">
            <span class="align-middle ml-50">Account Manager Commission/Other</span>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                v-if="editTriggerOther === false"
                v-b-tooltip.hover.top="'Edit'"
                @click="editTriggerOther = true"
                aria-pressed="false"
              >
                <feather-icon
                  icon="EditIcon"
                  size="1.3x"
                />
              </b-button>
              <b-button
                v-if="editTriggerOther === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                @click="closeCommissionOther"
                aria-pressed="false"
              >
                Close
              </b-button>
              <b-button
                v-if="editTriggerOther === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="saveCommissionOther"
                aria-pressed="false"
              >
                Save
              </b-button>
            </div>
          </b-card-title>
          <div class="card-border">
            <b-row v-for="(item, i) in commissionOther" :key="item.id" class="mt-1">
              <b-col cols="6">
                <b-form-group
                  label="Employee"
                  label-for="number"
                >
                  <v-select
                    v-model="item.employee"
                    dir="ltr"
                    label="name"
                    placeholder="Select employee"
                    :options="recruiters"
                    :disabled="!editTriggerOther"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="item.employee" cols="2">
                <b-form-group
                  label="Commission"
                  label-for="commission"
                >
                  <number-format
                    v-model="item.commission"
                    @change="calcCommissionOther(i, $event)"
                    :options="{ precision: 2, prefix: '', suffix: ' %', decimal: ',', thousand: '.', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTriggerOther"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col v-if="item.employee" cols="3">
                <b-form-group
                  label="Total"
                  label-for="total"
                >
                  <number-format
                    v-model="item.total"
                    :options="{ precision: 2, prefix: '', suffix: ' €', decimal: '.', thousand: ',', acceptNegative: false, isInteger: false  }"
                    :disabled="!editTriggerOther"
                  ></number-format>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="item.hasOwnProperty('id') && editTriggerOther"
                  :disabled="!editTriggerOther"
                  v-b-tooltip.hover.top="'Delete'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-secondary"
                  class="btn-icon mt-20"
                  @click="deleteCommissionOther(item, i)"
                  aria-pressed="false"
                >
                <feather-icon
                  icon="Trash2Icon"
                  size="1.3x"
                />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="6">
              <b-button
                class="mr-right-10 mt-10"
                v-ripple.400="'rgba(13, 110, 253, 0.15)'"
                variant="outline-primary"
                @click="newSelectOther"
                :disabled="!editTriggerOther"
              >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add account manager commission/other</span>
              </b-button>
            </b-col>
            </b-row>
          </div>
          <b-card-title class="mt-10 card-border-no-bottom font-medium-1 d-flex align-items-center justify-content-between">
            <span class="align-middle ml-50">Comments</span>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                v-if="editTriggerComments === false"
                v-b-tooltip.hover.top="'Edit'"
                @click="editTriggerComments = true"
                aria-pressed="false"
              >
                <feather-icon
                  icon="EditIcon"
                  size="1.3x"
                />
              </b-button>
              <b-button
                v-if="editTriggerComments === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon"
                @click="editTriggerComments = false"
                aria-pressed="false"
              >
                Close
              </b-button>
              <b-button
                v-if="editTriggerComments === true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="saveComments"
                aria-pressed="false"
              >
                Save
              </b-button>
            </div>
          </b-card-title>
          <div class="card-border">
            <b-row>
              <b-col cols="12">
                <b-form-textarea
                  id="comment"
                  v-model="detailRevenue.comment"
                  placeholder="Add comments here"
                  rows="3"
                  :disabled="!editTriggerComments"
                />
                <b-form-group
                  class="mb-0"
                  label-for="comments"
                  :description="Object.keys(detailRevenue.data).length === 0  || !detailRevenue.data['comment'] ? '' : 'Last update by ' + detailRevenue.data.comment + ' | ' + detailRevenue.data.comment_updated_at + '.'"
                />
              </b-col>
            </b-row>
          </div>
        </form>
    </b-modal>
    <b-modal
      id="edit-note-btn"
      ref="edit-note-btn"
      title="Notes"
      ok-title="Post"
      size="xl"
      centered
      cancel-variant="outline-secondary"
      @ok="handleNoteOk"
      :ok-disabled="isValid()"
      >
      <form
        ref="form"
        v-if="Object.entries(note).length > 0"
        @submit.stop.prevent="handleNoteOK"
      >
        <b-row>
          <b-col cols="12">
            <app-collapse>
              <app-collapse-item @visible="isVisible = $event" :title="isVisible ? 'Hide details' : 'Show details'">
                <b-table
                  style="height:100px;"
                  responsive
                  :items="items"
                  :fields="fields"
                  class="mb-0 text-nowrap"
                >
                  <template #cell(candidate.name)="data">
                    <a :href="data.item.link" target="_blank">
                      {{ data.item.candidate.name }}
                    </a>
                  </template>
                </b-table>
              </app-collapse-item>
            </app-collapse>
            <b-form-group
              class="mt-1"
              label="From"
              label-for="disabledInput"
            >
              <b-form-input
                id="disabledInput"
                disabled
                :value="$store.state.portal.profile.email"
              />
            </b-form-group>
            <b-form-group
              label="To"
              label-for="to"
            >
              <v-select
                v-model="noteSelected"
                dir="ltr"
                multiple
                label="full_name"
                :options="users"
              />
            </b-form-group>
            <b-form-group
              label="Content"
              label-for="note"
            >
            <Vueditor
              style="height: 100px;"
              ref="editor"
            ></Vueditor>
            </b-form-group>
          </b-col>
          <b-col cols="12" :class="notes.length > 0 ? 'mt-2 note-min-height' : 'mt-2'">
            <div class="modal-scroll">
              <app-timeline>
                <app-timeline-item
                  v-for="item in notes"
                  :key="item.id"
                  :title="item.created_by.first_name + ' ' + item.created_by.last_name"
                  :from="item.created_by.first_name + ' ' + item.created_by.last_name"
                  :to="item.to"
                  :subtitle="item.message"
                  :time="item.updated_at"
                  :html="true"
                  :buttonIcon="hideButton(item)"
                  variant="success"
                  @action="deleteNote(item.id)"
                  tooltip="Delete"
                  class="mb-1"
                />
              </app-timeline>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-modal
      id="modal-filters"
      centered
      title="Filters"
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Apply"
      @ok="handleQueryOk"
      @hidden="resetHidden"
      cancel-title="Cancel"
      cancel
    >
      <b-card>
        <and-or :options="queryOptions" :isFirst="isFirst" ref="andOr" @reset="resetAndOr()" v-model="advancedQuery"></and-or>
      </b-card>
    </b-modal>
    <!-- <b-modal
      id="modal-remove-line"
      centered
      title="Warning"
      cancel-variant="outline-secondary"
      ok-title="Yes"
      cancel-title="Cancel"
      cancel
      @ok="handleRemove"
    >
      <b-card-text class="font-weight-bolder">
        To continue, please read the following info:
      </b-card-text>
      <b-card-text class="text-warning">
        This action is NOT reversible.
      </b-card-text>
      <b-card-text class="font-weight-bolder">
        NOTE: All data is integrated with the Recruiter flow. If you remove a candidate's line that is currently active in the Recruiter flow, the record will be refreshed whenever the task is executed. 
      </b-card-text>
      <b-card-text class="mt-2">
        Are you sure you want to remove this item?
      </b-card-text>
    </b-modal> -->
  </div>
</template>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

#vs1__listbox {
  max-height: 250px !important;
}
</style>

<script>
import NumberFormat from '@/components/NumberFormat.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox, BButton, BModal, VBModal, BProgress, BContainer, BRow, BCol, BFormTextarea, BInputGroup, BFormSpinbutton, BCard,
  VBTooltip, BCardText, BCardTitle, BMedia, BMediaAside, BMediaBody, BTable, BFormCheckboxGroup, BCardHeader
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import Report from '@/api/services/Report.js'
import Auth from '@/api/services/Auth.js'
import vSelect from 'vue-select'
import { BFormDatepicker } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import VueSlider from 'vue-slider-component'
import AndOr from '@/components/query/AndOr.vue'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import moment from 'moment' 

const setCursor = (el, position) => {
  var setSelectionRange = function () { el.setSelectionRange(position, position) }
  if (el === document.activeElement) {
    setTimeout(setSelectionRange, 1)
  }
}
const setCursorPosition = (el, opt = defaultOptions) => {
  var positionFromEnd = el.value.length - el.selectionEnd
  el.value = format(el.value, opt)
  positionFromEnd = Math.max(positionFromEnd, opt.suffix.length)
  positionFromEnd = el.value.length - positionFromEnd
  positionFromEnd = Math.max(positionFromEnd, opt.prefix.length + 1)
  setCursor(el, positionFromEnd)
}
function fixed (precision) {
  return Math.max(0, Math.min(precision, 20))
} 
function addThousandSeparator (integer, separator) {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}
function joinIntegerAndDecimal (integer, decimal, separator) {
  return decimal ? integer + separator + decimal : integer
}
function toStr (value) {
  return value ? value.toString() : ''
} 

export default {
  components: {
    BFormCheckboxGroup,
    BCard,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BButton,
    BModal,
    BProgress,
    BContainer,
    BRow,
    BCol,
    vSelect,
    BFormTextarea,
    BFormDatepicker,
    Cleave,
    BInputGroup,
    flatPickr,
    VueSlider,
    BFormSpinbutton,
    ToastificationContent,
    AppTimeline,
    AppTimelineItem,
    AndOr,
    VueAutosuggest,
    AppCollapse,
    AppCollapseItem,
    BTable,
    BCardHeader,
    NumberFormat
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      checked: false,
      mergedOptions: {
        prefix: '',
        suffix: ' €',
        decimal: '.',
        thousand: ',',
        precision: 2,
        acceptNegative: true,
        isInteger: false
      },
      editTrigger: false,
      editTriggerSale: false,
      editTriggerOther: false,
      editTriggerComments: false,
      commissionRecruiter: [],
      commissionSale: [],
      commissionOther: [],
      last_update: '',
      cols: [],
      fields: [
        {
          key: 'recruiter.name',
          label: 'Recruiter'
        },
        {
          key: 'manager.name',
          label: 'Business/Account Manager'
        },
        {
          key: 'candidate',
          label: 'Candidate'
        },
        {
          key: 'customer.name',
          label: 'Client'
        },
        {
          key: 'job.name',
          label: 'Role'
        },
        {
          key: 'sent_date',
          label: 'Sent date',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return moment(String(value)).format('DD-MM-YYYY')
          }
        },
        {
          key: 'days_in_process',
          label: 'Days in process',
          formatter: (value, key, item) => {
            if (value === 0 && item.sent_date === moment(String(new Date())).format('YYYY-MM-DD')) {
              return 'Today'
            } else {
              return value
            }
          }
        },
        {
          key: 'last_update',
          label: 'Last update',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return moment(String(value)).format('DD-MM-YYYY')
          }
        },
        {
          key: 'days_in_stage',
          label: 'Days in stage',
          formatter: (value, key, item) => {
            if (value === 0 && item.last_update === moment(String(new Date())).format('YYYY-MM-DD')) {
              return 'Today'
            } else {
              return value
            }
          }
        },
        {
          key: 'status.description',
          label: 'Status'
        },
        {
          key: 'reason.description',
          label: 'Reason'
        },
        {
          key: 'disqualification_reason',
          label: 'Disqualification reason'
        },
        {
          key: 'current_stage.description',
          label: 'Current stage'
        },
        {
          key: 'next_stage.description',
          label: 'Next Stage'
        },
        {
          key: 'process_stage',
          label: 'Process stage',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return value + ' %'
          }
        },
        {
          key: 'start_date',
          label: 'Start date',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return moment(String(value)).format('DD-MM-YYYY')
          }
        },
        {
          key: 'salary',
          label: 'Salary',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
          }
        },
        {
          key: 'department.description',
          label: 'Type'
        },
        {
          key: 'fee_rate',
          label: 'Fee rate',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return (!Number.isNaN(Number(value.replace('.00', ''))) && Number(value.replace('.00', '')) !== 0 ? Number(value.replace('.00', '')) : '') + ' ' + item.fee_type
          }
        },
        {
          key: 'revenue',
          label: 'Revenue',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
          }
        },
        {
          key: 'end_date',
          label: 'Project end date',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return moment(String(value)).format('DD-MM-YYYY')
          }
        },
        {
          key: 'project_revenue',
          label: 'Project revenue',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
          }
        },
        {
          key: 'roi',
          label: 'ROI',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
          }
        },
        {
          key: 'roi_percentage',
          label: '% ROI',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return value + ' %'
          }
        },
        {
          key: 'pricelist_roi',
          label: 'Pricelist ROI',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
          }
        },
        {
          key: 'pricelist_roi_percentage',
          label: 'Pricelist % ROI',
          formatter: (value, key, item) => {
            if (value === null) {
              return ''
            }
            return value + ' %'
          }
        },
        {
          key: 'invoice_number',
          label: 'Invoice number'
        },
        {
          key: 'invoice_date',
          label: 'Invoice date',
          formatter: (value, key, item) => {
            if (!value) {
              return ''
            }
            return moment(String(value)).format('DD-MM-YYYY')
          }
        },
      ],
      items: [],
      queryOptions: {
        keys: [{
          name: 'Recruiter',
          id: 'recruiter.name'
        },{
          name: 'Business/Account Manager',
          id: 'manager.name'
        },{
          name: 'Candidate',
          id: 'candidate.name'
        },{
          name: 'Client',
          id: 'customer.name'
        },{
          name: 'Role',
          id: 'job.name'
        },{
          name: 'Sent date',
          id: 'sent_date'
        },{
          name: 'Days in process',
          id: 'days_in_process'
        },{
          name: 'Last update',
          id: 'last_update'
        },{
          name: 'Days in stage',
          id: 'days_in_stage'
        },{
          name: 'Status',
          id: 'status.description'
        },{
          name: 'Reason',
          id: 'reason.description'
        },{
          name: 'Current stage',
          id: 'current_stage.description'
        },{
          name: 'Next stage',
          id: 'next_stage.description'
        },{
          name: 'Process stage',
          id: 'process_stage'
        },{
          name: 'Start date',
          id: 'start_date'
        },{
          name: 'Salary',
          id: 'salary'
        },{
          name: 'Type',
          id: 'department.description'
        },{
          name: 'Fee rate',
          id: 'fee_rate'
        },{
          name: 'Revenue',
          id: 'revenue'
        },{
          name: 'Project revenue',
          id: 'project_revenue'
        },{
          name: 'ROI',
          id: 'roi'
        },{
          name: '% ROI',
          id: 'roi_percentage'
        },{
          name: 'Pricelist ROI',
          id: 'pricelist_roi'
        },{
          name: 'Pricelist % ROI',
          id: 'pricelist_roi_percentage'
        },{
          name: 'Project end date',
          id: 'end_date'
        }],
        operators: [{
          name: 'Choose Operator',
          id: -99
        },{
          name: 'moddvdre',
          id: '>'
        },{
          name: 'equal',
          id: '='
        },{
          name: 'less',
          id: '<'
        }]
      },
      // boxTwo: '',
      // remove: false,
      isFirst: true,
      eu: '',
      users: [],
      filteredOptions: [],
      limit: 10,
      noteSelected: '',
      selected: [],
      pageLength: 100,
      dir: false,
      date: '',
      totalRevenue: 0,
      totalPr: 0,
      totalRoi: 0,
      totalPricelist: 0,
      isVisible: true,
      qlEditor: {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{ 'color': [
              "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
              "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
              "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
              "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
              "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
            ]}, 
            { 'background': [
              "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
              "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
              "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
              "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
              "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
            ]}
          ],
          ]
        }
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID',
          },
          hidden: true
        },
        {
          label: 'Job ID',
          field: 'job_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search job ID',
          },
          hidden: true
        },
        {
          label: 'Recruiter',
          field: 'recruiter.name',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search recruiter',
          },
          hidden: false,
          // type: 'select',
          // selected: '',
          // options: []
        },
        {
          label: 'Business/account manager',
          field: 'manager.name',
          width: '220px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search business/account manager',
          },
          hidden: false,
        },
        {
          label: 'Candidate',
          field: 'candidate',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search candidate',
          },
          hidden: false
        },
        {
          label: 'Client',
          field: 'customer.name',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search client',
          },
          hidden: false
        },
        {
          label: 'Role',
          field: 'job.name',
          width: '300px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search role',
          },
          hidden: false
        },
        {
          label: 'Sent date',
          field: 'sent_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search sent date',
            filterFn: this.dateRangeFilter
          },
          date: '',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy'
        },
        {
          label: 'Days in process',
          field: 'days_in_process',
          filterOptions: {
            enabled: false,
            placeholder: 'Search days in process',
          },
          type: 'number',
          hidden: false
        },
        {
          label: 'Last update',
          field: 'last_update',
          filterOptions: {
            enabled: true,
            placeholder: 'Search last update',
            filterFn: this.dateRangeFilter
          },
          date: '',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy'
        },
        {
          label: 'Days in stage',
          field: 'days_in_stage',
          filterOptions: {
            enabled: false,
            placeholder: 'Search days in stage',
          },
          type: 'number',
          hidden: false
        },
        {
          label: 'Status',
          field: 'status.description',
          width: '120px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search status',
          },
          hidden: false
        },
        {
          label: 'Reason',
          field: 'reason.description',
          width: '120px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search reason',
          },
          hidden: false
        },
        {
          label: 'Disqualification reason',
          field: 'disqualification_reason',
          filterOptions: {
            enabled: true,
            placeholder: 'Search disqualification reason',
          },
          hidden: false
        },
        {
          label: 'Current stage',
          field: 'current_stage.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search current stage',
          },
          hidden: false
        },
        {
          label: 'Next stage',
          field: 'next_stage.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search next stage',
          },
          hidden: false
        },
        {
          label: 'Process stage',
          field: 'process_stage',
          filterOptions: {
            enabled: true,
            placeholder: 'Search process stage',
            filterFn: this.numberRangeFilter
          },
          hidden: false,
          type: 'number',
          model: 1,
          value: [0, 100],
          tdClass: 'text-left',
          thClass: 'text-left'
        },
        {
          label: 'Start date',
          field: 'start_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search sent date',
            filterFn: this.dateRangeFilter
          },
          date: '',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy'
        },
        {
          label: 'Salary',
          field: 'salary',
          width: '100px',
          type: 'number',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search salary',
          },
          hidden: false,
        },
        {
          label: 'Type',
          field: 'department.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search type',
          },
          hidden: false
        },
        {
          label: 'Fee rate',
          field: 'fee_rate',
          type: 'number',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search fee rate',
          },
          hidden: false,
        },
        {
          label: 'Value type',
          field: 'fee_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Search value type',
          },
          hidden: true,
        },
        {
          label: 'Revenue',
          field: 'revenue',
          width: '100px',
          type: 'number',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search revenue',
          },
          hidden: false,
        },
        {
          label: 'Project end date',
          field: 'end_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search project end date',
            filterFn: this.dateRangeFilter
          },
          date: '',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy'
        },
        {
          label: 'Project revenue',
          field: 'project_revenue',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search project revenue',
          },
          hidden: false,
        },
        {
          label: 'ROI',
          field: 'roi',
          width: '100px',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search ROI',
          },
          hidden: false,
        },
        {
          label: '% ROI',
          field: 'roi_percentage',
          type: 'number',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search % ROI',
          },
          hidden: false,
        },
        {
          label: 'Pricelist ROI',
          field: 'pricelist_roi',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search pricelist ROI',
          },
          hidden: false,
        },
        {
          label: 'Pricelist % ROI',
          field: 'pricelist_roi_percentage',
          type: 'number',
          filterOptions: {
            filterFn: this.numberFilter,
            enabled: true,
            placeholder: 'Search pricelist % ROI',
          },
          hidden: false,
        },
        {
          label: 'Invoice number',
          field: 'invoice_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search invoice number',
          },
          hidden: false,
        },
        {
          label: 'Invoice date',
          field: 'invoice_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search invoice date',
            filterFn: this.dateRangeFilter
          },
          date: '',
          hidden: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy'
        },
        {
          label: 'Note',
          field: 'note',
          width: '300px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search note',
          },
          hidden: false,
        },
        {
          label: 'Action',
          field: 'action',
        }
      ],
      myColumns: [
        {
          html: '<span style="min-width: 100px">ID</span>',
          value: 'id',
          disabled: true
        },
        {
          text: 'Job ID',
          value: 'job_id',
          disabled: true
        },
        {
          text: 'Recruiter',
          value: 'recruiter.name',
        },
        {
          text: 'Business/account manager',
          value: 'manager.name',
        },
        {
          text: 'Candidate',
          value: 'candidate.name',
        },
        {
          text: 'Client',
          value: 'customer.name',
        },
        {
          text: 'Role',
          value: 'job.name',
        },
        {
          text: 'Sent date',
          value: 'sent_date',
        },
        {
          text: 'Days in process',
          value: 'days_in_process',
        },
        {
          text: 'Last update',
          value: 'last_update',
        },
        {
          text: 'Days in stage',
          value: 'days_in_stage',
        },
        {
          text: 'Status',
          value: 'status.description',
        },
        {
          text: 'Reason',
          value: 'reason.description',
        },
        {
          text: 'Disqualification reason',
          value: 'disqualification_reason',
        },
        {
          text: 'Current stage',
          value: 'current_stage.description',
        },
        {
          text: 'Next stage',
          value: 'next_stage.description',
        },
        {
          text: 'Process stage',
          value: 'process_stage',
        },
        {
          text: 'Start date',
          value: 'start_date',
        },
        {
          text: 'Salary',
          value: 'salary',
        },
        {
          text: 'Type',
          value: 'department.description',
        },
        {
          text: 'Fee rate',
          value: 'fee_rate',
        },
        {
          text: 'Value type',
          value: 'fee_type',
          disabled: true
        },
        {
          text: 'Revenue',
          value: 'revenue',
        },
        {
          text: 'Project end date',
          value: 'end_date',
        },
        {
          text: 'Project revenue',
          value: 'project_revenue',
        },
        {
          text: 'ROI',
          value: 'roi',
        },
        {
          text: '% ROI',
          value: 'roi_percentage',
        },
        {
          text: 'Pricelist ROI',
          value: 'pricelist_roi',
        },
        {
          text: 'Pricelist % ROI',
          value: 'pricelist_roi_percentage',
        },
        {
          text: 'Invoice number',
          value: 'invoice_number',
        },
        {
          text: 'Invoice date',
          value: 'invoice_date',
        },
        {
          text: 'Note',
          value: 'note',
        }
      ],
      selectedColumns: [
        'id',
        'job_id',
        'fee_type',
      ],
      show: true,
      rows: [],
      searchTerm: '',
      total: 0,
      isLoading: false,
      detail: {},
      recruiters: [],
      managers: [],
      statusOptions: [],
      reasonOptions: [],
      form: {},
      query: '',
      myFilter: [],
      departments: [],
      detailRevenue: {},
      ordering: '',
      note: {},
      notes: [],
      advancedQuery: [
        {
          key: '',
          operator: 0,
          value: ''
        }
      ],
      backupAdvancedQuery: []
    }
  },
  watch: {
    searchTerm: function (value) {
      this.loadData(1, this.ordering, '', value)
    },
    'detailRevenue.project_revenue': function (value) {
      this.calcRoi()
    },
    'detailRevenue.cost': function (value) {
      this.calcRoi()
    },
    'detailRevenue.total_commission_recruiter': function (value) {
      this.calcRoi()
    },
    'detailRevenue.total_commission_sale': function (value) {
      this.calcRoi()
    },
    'detailRevenue.total_commission_other': function (value) {
      this.calcRoi()
    }

  },
  methods: {
    onSwitchChange() {
      this.loadData(1, this.ordering, this.query.includes('?page=') ?  this.query.slice(7) : this.query, '', false, this.checked)
    },
    // calcRoi () {
    //   if ((this.detailRevenue.project_revenue && this.detailRevenue.project_revenue !== 0 && this.detailRevenue.project_revenue !== '0.00') && (this.detailRevenue.cost && this.detailRevenue.cost !== '0.00' && this.detailRevenue.cost !== 0)) {
    //     this.detailRevenue.roi = this.detailRevenue.project_revenue - (parseFloat(this.detailRevenue.cost) + this.detailRevenue.total_commission_recruiter + this.detailRevenue.total_commission_sale + this.detailRevenue.total_commission_other)
    //     this.detailRevenue.roi_percentage = ((this.detailRevenue.project_revenue - parseFloat(this.detailRevenue.cost) - this.detailRevenue.total_commission_recruiter - this.detailRevenue.total_commission_sale - this.detailRevenue.total_commission_other) / this.detailRevenue.project_revenue) * 100
    //   } else {
    //     this.detailRevenue.roi = ''
    //     this.detailRevenue.roi_percentage = ''
    //   }
    // },
    calcRoi() {
      if (this.detailRevenue.cost && this.detailRevenue.cost !== '0.00' && this.detailRevenue.cost !== 0) {
        if (this.detailRevenue.project_revenue && this.detailRevenue.project_revenue !== 0 && this.detailRevenue.project_revenue !== '0.00') {
          this.detailRevenue.roi = this.detailRevenue.project_revenue - (parseFloat(this.detailRevenue.cost) + this.detailRevenue.total_commission_recruiter + this.detailRevenue.total_commission_sale + this.detailRevenue.total_commission_other)
          this.detailRevenue.roi_percentage = ((this.detailRevenue.project_revenue - parseFloat(this.detailRevenue.cost) - this.detailRevenue.total_commission_recruiter - this.detailRevenue.total_commission_sale - this.detailRevenue.total_commission_other) / this.detailRevenue.project_revenue) * 100
        } else {
          this.detailRevenue.roi = this.detailRevenue.project_revenue - (parseFloat(this.detailRevenue.cost) + this.detailRevenue.total_commission_recruiter + this.detailRevenue.total_commission_sale + this.detailRevenue.total_commission_other)
          this.detailRevenue.roi_percentage = ''
        }
      } else {
        this.detailRevenue.roi = ''
        this.detailRevenue.roi_percentage = ''
      }
    },
    updateRoi () {
      Report.getAFinanceReport(this.detailRevenue.id).then(response => {
        this.detailRevenue.total_commission_recruiter = response.data.total_commission_recruiter
        this.detailRevenue.total_commission_sale = response.data.total_commission_sale
        this.detailRevenue.total_commission_other = response.data.total_commission_other
      })
    },
    // removeAction () {
    //   this.selected.forEach(item => {
    //     Report.deleteFinance(item.id).then(response => {
    //       this.loadData()
    //     })
    //   })
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //         title: 'Success',
    //         icon: 'CheckCircleIcon',
    //         text: "Candidate has been successfully deleted! 🥳",
    //         variant: 'success',
    //       },
    //     },
    //     {
    //       position: 'top-center',
    //   })        
    // },
    calcCommissionRecruiter (i, event) {
      if (event > 0) {
        this.commissionRecruiter[i].commission = event
        this.commissionRecruiter[i].total = ((event * parseFloat(this.detailRevenue.revenue)) / 100).toFixed(2)
      }
    },
    calcCommissionSale (i, event) {
      if (event > 0) {
        this.commissionSale[i].commission = event
        this.commissionSale[i].total = ((event * parseFloat(this.detailRevenue.revenue)) / 100).toFixed(2)
      }
    },
    calcCommissionOther (i, event) {
      if (event > 0) {
        this.commissionOther[i].commission = event
        this.commissionOther[i].total = ((event * parseFloat(this.detailRevenue.revenue)) / 100).toFixed(2)
      }
    },
    deleteCommissionRecruiter (item, index) {
      Report.deleteCommissionRecruiter(item.id).then(response => {
        this.updateRoi()
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: item.recruiter.name + "'s commission has been successfully deleted! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
        this.commissionRecruiter.splice(index, 1)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      })
      this.editTrigger = false
    },
    deleteCommissionSale (item, index) {
      Report.deleteCommissionSale(item.id).then(response => {
        this.updateRoi()
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: item.manager.name + "'s commission has been successfully deleted! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
        this.commissionSale.splice(index, 1)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      })
      this.editTriggerSale = false
    },
    saveComments () {
      Report.patchFinanceReport({
        id: this.detailRevenue.id,
        comment: this.detailRevenue.comment
      }).then(response => {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: "Comment has been successfully saved! 🥳",
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Error',
            icon: 'XIcon',
            text: "Something went wrong! Please, try again later.",
            variant: 'danger',
          },
        },
        {
          position: 'bottom-center',
        })
      })
      this.editTriggerComments = false
    },
    deleteCommissionOther (item, index) {
      Report.deleteCommissionOther(item.id).then(response => {
        this.updateRoi()
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: item.employee.name + "'s commission has been successfully deleted! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
        this.commissionOther.splice(index, 1)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      })
      this.editTriggerOther = false
    },
    async saveCommissionRecruiter () {
      var message = 0
      var model = 'put'
      for (let i = 0; i < this.commissionRecruiter.length; i++) {
        const item = this.commissionRecruiter[i]
        if (item.hasOwnProperty('id')) {
          await Report.putCommissionRecruiter({
            id: item.id,
            report: item.report,
            recruiter: item.recruiter.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
          })
        } else {
          await Report.postCommissionRecruiter({
            report: item.report,
            recruiter: item.recruiter.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
            model = 'post'
            this.commissionRecruiter[i].id = response.data.id
          })
        }
      }
      await this.updateRoi()
      if (message === this.commissionRecruiter.length && model === 'put') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission recruiter has been successfully updated! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else if (message === this.commissionRecruiter.length && model === 'post') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission recruiter has been successfully added! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      }
      this.editTrigger = false
    },
    closeCommissionRecruiter () {
      for (let i = 0; i < this.commissionRecruiter.length; i++) {
        if (!this.commissionRecruiter[i].id) {
          this.commissionRecruiter.splice(i, 1)
        }
      }
      this.editTrigger = false
    },
    async saveCommissionSale () {
      var message = 0
      var model = 'put'
      for (let i = 0; i < this.commissionSale.length; i++) {
        const item = this.commissionSale[i]
        if (item.hasOwnProperty('id')) {
          await Report.putCommissionSale({
            id: item.id,
            report: item.report,
            manager: item.manager.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
          })
        } else {
         await Report.postCommissionSale({
            report: item.report,
            manager: item.manager.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
            model = 'post'
            this.commissionSale[i].id = response.data.id
          })
        }
      }
      await this.updateRoi()
      if (message === this.commissionSale.length && model === 'put') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission sale has been successfully updated! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else if (message === this.commissionSale.length && model === 'post') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission sale has been successfully added! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      }
      this.editTriggerSale = false
    },
    closeCommissionSale () {
      for (let i = 0; i < this.commissionSale.length; i++) {
        if (!this.commissionSale[i].id) {
          this.commissionSale.splice(i, 1)
        }
      }
      this.editTriggerSale = false
    },
    async saveCommissionOther () {
      var message = 0
      var model = 'put'
      for (let i = 0; i < this.commissionOther.length; i++) {
        const item = this.commissionOther[i]
        if (item.hasOwnProperty('id')) {
        await Report.putCommissionOther({
            id: item.id,
            report: item.report,
            employee: item.employee.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
          })
        } else {
          await Report.postCommissionOther({
            report: item.report,
            employee: item.employee.id,
            commission: item.commission,
            total: item.total
          }).then(response => {
            message++
            model = 'post'
            this.commissionOther[i].id = response.data.id
          })
        }
      }
      await this.updateRoi()
      if (message === this.commissionOther.length && model === 'put') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission other has been successfully updated! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else if (message === this.commissionOther.length && model === 'post') {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: "Commission other has been successfully added! 🥳",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Error',
              icon: 'XIcon',
              text: "Something went wrong! Please, try again later.",
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
        })
      }
      this.editTriggerOther = false
    },
    closeCommissionOther () {
      for (let i = 0; i < this.commissionOther.length; i++) {
        if (!this.commissionOther[i].id) {
          this.commissionOther.splice(i, 1)
        }
      }
      this.editTriggerOther = false
    },
    newSelectRecruiter () {
      this.commissionRecruiter.push({
        report: this.detailRevenue.id,
        recruiter: '',
        commission: 0,
        total: 0
      })
    },
    newSelectSale () {
      this.commissionSale.push({
        report: this.detailRevenue.id,
        manager: '',
        commission: 0,
        total: 0
      })
    },
    newSelectOther () {
      this.commissionOther.push({
        report: this.detailRevenue.id,
        employee: '',
        commission: 0,
        total: 0
      })
    },
    saveColumns () {
      this.$store.commit('portal/setFinanceColumns', [])
      Report.getFinanceColumns().then(response => {
        if (response.data.results.length > 0) {
          var data = response.data.results[0]
          data.user = data.user.id
          data.data = []
          data.data = this.selectedColumns
          Report.putFinanceColumns(data).then(response => {
            this.getColumns()
          })
        } else {
          Report.postFinanceColumns({
            user: this.$store.state.portal.profile.id,
            data: this.selectedColumns
          }).then(response => {
            this.getColumns()
          })
        }
      })
    },
    disabledStatus (value) {
      switch (value.description) {
        case 'In progress':
          return false
        case 'Hired':
          return false
        case 'Disqualified':
          return false
        case 'No allocation':
          return false
        default:
          return true
      }
    },
    syncWithRF() {
      Report.syncRf().then(response => {
        if (response.data.status === 'Done!') {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your request has been received and will be completed in 30 minutes...',
              variant: 'success',
              hideProgressBar: false
            },
          },
          {
            position: 'top-center',
            timeout: 8000
          })
        } else {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'AlertTriangleIcon',
              text: response.data.status,
              variant: 'warning',
              hideProgressBar: false
            },
          },
          {
            position: 'top-center',
            timeout: 8000
          })
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Fail',
              icon: 'XIcon',
              text: 'Error executing the API. Please try again later.',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
      })
    },
    getUsers() {
      Auth.getDamiaUsers().then(response => {
        this.users = response.data
      })
    },
    onNoteSelected (item) {
      this.noteSelected = item.item.email
      return item.item.first_name + ' ' + item.item.last_name + ' (' + item.item.email + ')'
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filtered = this.users.filter(item => item.email.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filtered,
      }]
    },
    resetHidden () {
      if (this.backupAdvancedQuery.length > 0 && this.myFilter.length > 0) {
        this.advancedQuery = this.backupAdvancedQuery
      }
    },
    resetAndOr () {
      this.backupAdvancedQuery = this.advancedQuery
      this.advancedQuery = [
        {
          key: '',
          operator: 0,
          value: ''
        }
      ]
    },
    pressedF () {
      return true
    },
    myQuery (query) {
      this.advancedQuery = query.rules
    },
    colorRandom () {
      var data = [
        'primary',
        'secondary',
        'warning',
        'danger',
        'info',
        'dark',
        'sucess'
      ]
      return data[Math.floor(Math.random() * 6)]
    },
    hideButton (item) {
      if (item.created_by.id === this.$store.state.portal.profile.id) {
        return 'Trash2Icon'
      }
      return ''
    },
    deleteNote (id) {
      this.$loading(true)
      Report.deleteNote(id).then(response => {
        this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your note has been successfully deleted!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        Report.getNote(this.note.rp).then(r => {
          this.notes = r.data.results
          var len = this.notes.length - 1
          this.rows.forEach((element, index) => {
            if (len >= 0) {
              if (element.id === r.data.results[len].rp) {
                this.rows[index].note = r.data.results[len].message
              }
            } else {
              if (element.id === this.note.rp) {
                this.rows[index].note = ''
              }
            }
          })
          this.$loading(false)
        })
      })
    },
    download () {
      this.loadData(1, this.ordering, this.query.includes('?page=') ?  this.query.slice(7) : this.query, '', true)
      // var file = window.open('http://localhost:8000/api/v1/reports/export/finance/' + this.query.slice(7))
      // file.close()
    },
    isValidForm () {
      if (this.detail.fee_rate === '0.00' && this.detail.fee_type === '') {
        return true
      }
      if ((this.detail.fee_rate !== '0.00' && this.detail.fee_rate !== '') && this.detail.fee_type !== '') {
        return true
      }
      return false
    },
    isValid () {
      if (this.detail.fee_rate === '0.00' && this.detail.fee_type === '') {
        return false
      }
      if ((this.detail.fee_rate !== '0.00' && this.detail.fee_rate !== '') && this.detail.fee_type !== '') {
        return false
      }
      return true
    },
    clear () {
      // this.query = ''
      // this.loadData()
      this.$router.go()
    },
    updateFiltersOnKeyup (column, value) {
      // if the trigger is enter, we don't filter on keyup
      if (column.filterOptions.trigger === 'enter') return;
      this.updateFilters(column, value);
    },
    updateFiltersOnEnter(column, value) {
      if (this.timer) clearTimeout(this.timer);
      this.updateFiltersImmediately(column.field, value);
    },
    updateFiltersImmediately(field, value) {
      this.$set(this.columnFilters, this.fieldKey(field), value);
      this.$emit('filter-changed', this.columnFilters);
    },
    updateFilters(column, value) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.updateFiltersImmediately(column.field, value);
      }, 400);
    },
    handleQueryOk (bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleQuerySubmit()
    },
    handleQuerySubmit () {
      this.$loading(true)
      this.$refs['financeTable'].reset()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-filters')
      })
      var query = ''
      var myFilter = []
      this.advancedQuery.forEach(item => {
        if (item.key !== '') {
          query = query + '&' + this.normalizeFk(item.key) + item.operator + item.value
          myFilter.push(this.normalize(item.key) + ' ' + this.normalizeOperator(item.operator) + ' ' + item.value)
        }
      })
      this.query = query
      this.myFilter = myFilter
      this.loadData(1, this.ordering, query)
    },
    refreshData () {
      this.$loading(true)
      this.$refs['financeTable'].reset()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-filters')
      })
      var query = ''
      var myFilter = []
      this.advancedQuery.forEach(item => {
        if (item.key !== '') {
          query = query + '&' + this.normalizeFk(item.key) + item.operator + item.value
          myFilter.push(this.normalize(item.key) + ' ' + this.normalizeOperator(item.operator) + ' ' + item.value)
        }
      })
      this.query = query
      this.myFilter = myFilter
      this.loadData(1, this.ordering, query)
    },
    normalizeOperator (value) {
      switch(value) {
        case '__icontains=':
          return 'contains'
        case '=':
          return 'equals'
        case '__lte=':
          return 'less than or equal to'
        case '__lt=':
          return 'less than'
        case '__gte=':
          return 'greater than or equal to'
        case '__gt=':
          return 'greater than'
        default:
          return value
      }
    },
    normalizeFk (value) {
      switch (value) {
        case 'recruiter.name':
          return 'recruiter__name'
        case 'manager.name':
          return 'manager__name'
        case 'department.description':
          return 'department__description'
        case 'current_stage.description':
          return 'current_stage__description'
        case 'next_stage.description':
          return 'next_stage__description'
        case 'status.description':
          return 'status__description'
        case 'candidate.name':
          return 'candidate__name'
        default:
          return value
      }
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleRfOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide('sync-rf')
      })
      this.syncWithRF()
    },
    handleNoteOk (bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleNoteSubmit()
    },
    handleRevenueOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleRevenueSubmit()
    },
    handleNoteSubmit () {
      this.$loading(true)
      this.note.message = this.$refs.editor.getContent()
      var to = []
      if (this.noteSelected.length > 0) {
        this.noteSelected.forEach(item => {
          to.push(item.id)
        })
      }
      this.note.to = to
      Report.setNote(this.note).then(response => {
        this.rows.forEach((element, index) => {
          if (element.id === response.data.rp) {
            this.rows[index].note = response.data.message.replace(/(<([^>]+)>)/gi, '')
          }
        })
        this.note.message = ''
        this.noteSelected = ''
        this.to = ''
        this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your note have been successfully posted!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.$refs.editor.setContent('')
        Report.getNote(this.note.rp).then(r => {
          this.notes = r.data.results
          this.$loading(false)
        })
      }).catch(() => {
        this.$loading(false)
      })
    },
    updateDaysInStage () {
      if (this.detailRevenue.end_date && this.detailRevenue.status.description === 'No allocation') {
        var dt1 = new Date(this.detailRevenue.end_date)
        var dt2 = new Date(this.detailRevenue.last_update)
        var diff1 = dt1.getTime() - dt2.getTime();
        this.detailRevenue.days_in_stage = Math.floor(diff1 / 86400000);
        return this.detailRevenue.days_in_stage
      } else if (this.detailRevenue.end_date && this.detailRevenue.status.description === 'Hired' || this.detailRevenue.status.description === 'Project end' || this.detailRevenue.status.description === 'Internalized') {
        var dt1 = new Date(this.detailRevenue.end_date)
        var dt2 = new Date(this.detailRevenue.start_date)
        var diff1 = dt1.getTime() - dt2.getTime();
        this.detailRevenue.days_in_stage = Math.floor(diff1 / 86400000);
        return this.detailRevenue.days_in_stage
      }
    },
    handleRevenueSubmit () {
      this.$loading(true)
      this.$nextTick(() => {
        this.$bvModal.hide('edit-revenue-btn')
      })
      Report.putFinanceReport({
        id: this.detailRevenue.id,
        is_no_allocation: this.detailRevenue.is_no_allocation,
        project_revenue: this.detailRevenue.project_revenue,
        cost: this.detailRevenue.cost,
        roi: this.detailRevenue.roi ? parseFloat(this.detailRevenue.roi).toFixed(2) : null,
        roi_percentage: this.detailRevenue.roi_percentage ? parseFloat(this.detailRevenue.roi_percentage).toFixed(2) : null,
        pricelist_roi: this.detailRevenue.pricelist_roi,
        pricelist_roi_percentage: this.detailRevenue.pricelist_roi_percentage,
        comment: this.detailRevenue.comment,
        status: this.detailRevenue.status.description && this.detailRevenue.status.description.id ? this.detailRevenue.status.description.id : this.detailRevenue.status.id,
        reason: this.detailRevenue.reason === null ? null : this.detailRevenue.reason.description && this.detailRevenue.reason.description.id ? this.detailRevenue.reason.description : this.detailRevenue.reason,    
        no_allocation: this.detailRevenue.no_allocation,
        end_date: this.detailRevenue.end_date === "" ? null : this.detailRevenue.end_date,
        invoice_number: this.detailRevenue.invoice_number,
        invoice_date: this.detailRevenue.invoice_date === "" ? null : this.detailRevenue.invoice_date,
        days_in_stage: this.updateDaysInStage(),
        last_update_no_allocation: this.detailRevenue.last_update_no_allocation
      }).then(response => {
        // this.$loading(false)
        // this.loadData()
        this.rows.forEach((element, index) => {
          if (element.id === response.data.id) {
            this.rows[index].id = response.data.id
            this.statusOptions.forEach(item => {
              if (item.id === response.data.status) {
                this.rows[index].status = item
              }
            })
            if (response.data.reason) {
              this.reasonOptions.forEach(item => {
                if (item.id === response.data.reason.id) {
                  this.rows[index].reason = item
                }
              })
            } else {
              this.rows[index].reason = null
            }
            this.rows[index].project_revenue = response.data.project_revenue
            this.rows[index].comment = response.data.comment
            this.rows[index].cost = response.data.cost
            this.rows[index].roi = response.data.roi
            this.rows[index].roi_percentage = response.data.roi_percentage
            this.rows[index].total_commission_recruiter = response.data.total_commission_recruiter
            this.rows[index].total_commission_sale = response.data.total_commission_sale
            this.rows[index].total_commission_other = response.data.total_commission_other
            this.rows[index].roi_percentage = response.data.roi_percentage
            this.rows[index].pricelist_roi = response.data.pricelist_roi
            this.rows[index].pricelist_roi_percentage = response.data.pricelist_roi_percentage
            this.rows[index].data = response.data.data
            this.rows[index].no_allocation = response.data.no_allocation
            this.rows[index].end_date = response.data.end_date
            this.rows[index].invoice_number = response.data.invoice_number
            this.rows[index].invoice_date = response.data.invoice_date
            this.rows[index].revenue = response.data.revenue
            this.rows[index].days_in_stage = response.data.days_in_stage
            this.rows[index].last_update_no_allocation = response.data.last_update_no_allocation
            this.rows[index].is_no_allocation = response.data.is_no_allocation
          }
        })
        this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your changes have been successfully saved!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.$loading(false)
      })
    },
    updateData() {
      this.$nextTick(() => {
        this.$bvModal.hide('edit-btn')
      })
      var manager = ''
      try {
        manager = (this.detail.manager ? (typeof this.detail.manager.name === 'object' ? this.detail.manager.name.id : this.detail.manager.id) : this.detail.manager)
      } catch (error) {
        manager = ''
      }
      var recruiter = ''
      try {
        recruiter = (this.detail.recruiter && this.detail.recruiter.name ? (this.detail.recruiter.name.id ? this.detail.recruiter.name.id : this.detail.recruiter.id) : '')
      } catch (error) {
        recruiter = ''
      }
      const data = {
        id: this.detail.id,
        recruiter: recruiter,
        manager: manager,
        department: this.detail.department.description.id ? this.detail.department.description.id : this.detail.department.id,
        job: this.detail.job.name.id ? this.detail.job.name.id : this.detail.job.id,
        sent_date: this.detail.sent_date,
        last_update: this.detail.last_update,
        start_date: this.detail.start_date,
        end_date: this.detail.end_date === "" ? null : this.detail.end_date,
        salary: this.detail.salary ? this.detail.salary.replace(',', '') : '',
        fee_rate: this.detail.fee_rate ? this.detail.fee_rate.replace(',', '') : '',
        revenue: this.detail.revenue ? this.detail.revenue.replace(',', '') : '',
        fee_type: this.detail.fee_type,
        invoice_number: this.detail.invoice_number,
        invoice_date: this.detail.invoice_date === "" ? null : this.detail.invoice_date,
        note: this.detail.note,
        live: this.detail.live,
        last_update_no_allocation: this.detail.last_update_no_allocation,
        is_no_allocation: this.detail.is_no_allocation
      }
      Report.putFinanceReport(data).then(response => {
        // this.loadData()
        this.rows.forEach((element, index) => {
          if (element.id === response.data.id) {
            this.rows[index].id = response.data.id
            this.departments.forEach(item => {
              if (item.id === response.data.department) {
                this.rows[index].department = item
              }
            })
            this.recruiters.forEach(item => {
              if (item.id === response.data.recruiter) {
                this.rows[index].recruiter = item
              }
            })
            this.managers.forEach(item => {
              if (item.id === response.data.manager) {
                this.rows[index].manager = item
              }
            })
            this.rows[index].job = response.data.job
            this.rows[index].sent_date = response.data.sent_date
            this.rows[index].last_update = response.data.last_update
            this.rows[index].start_date = response.data.start_date
            this.rows[index].end_date = response.data.end_date
            this.rows[index].salary = response.data.salary
            this.rows[index].fee_rate = response.data.fee_rate
            this.rows[index].revenue = response.data.revenue
            this.rows[index].fee_type = response.data.fee_type
            this.rows[index].invoice_number = response.data.invoice_number
            this.rows[index].invoice_date = response.data.invoice_date
            this.rows[index].note = response.data.note
            this.rows[index].live = response.data.live
            this.rows[index].data = response.data.data
            this.rows[index].last_update_no_allocation = response.data.last_update_no_allocation
            this.rows[index].is_no_allocation = response.data.is_no_allocation
          }
        })
        this.$refs['financeTable'].reset()
        this.$nextTick(() => {
          this.$bvModal.hide('modal-filters')
        })
        this.loadData(1, this.ordering, this.query)
        this.$loading(false)
        this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your changes have been successfully saved!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
        } )
      })
    },
    handleSubmit () {
      this.$loading(true)
      this.updateData()
    },
    resetModal () {
      // this.loadData()
    },
    edit (value) {
      // this.detail = new Object()
      // this.detail = value
      this.$loading(true)
      Report.getAFinanceReport(value.id).then(response => {
        this.detail = response.data
        if (this.detail.last_update_no_allocation) {
          this.last_update = this.detail.last_update_no_allocation
        } else {
          this.last_update = ''
        }
        this.$loading(false)
      })
    },
    editNote (value) {
      this.items = [value]
      Report.getNote(value.id).then(response => {
        this.notes = response.data.results
      })
      this.note = {
        rp: value.id,
        message: ''
      }
    },
    editRevenue (value) {
      this.detailRevenue = value
      Report.getCommissionRecruiter(value.id).then(response => {
        this.commissionRecruiter = response.data.results
      })
      Report.getCommissionSale(value.id).then(response => {
        this.commissionSale = response.data.results
      })
      Report.getCommissionOther(value.id).then(response => {
        this.commissionOther = response.data.results
      })
    },
    onPageChange (params) {
      this.loadData(params.currentPage, this.ordering, this.query)
    },
    onSortChange (params) {
      var field = params[0].field.replace('.', '__')
      if (params[0].type !== 'asc') {
        field = '-' + field
      }
      if (field !== 'action') {
        this.loadData(1, field, this.query)
        this.ordering = field
      }
    },
    onColumnFilter (params) {
      setTimeout(() => {
        this.$loading(true)
      var query = ''
      var myFilter = []
      for (var [key, value] of Object.entries(params.columnFilters)) {
        if (value !== '') {
          if (key === 'last_update' || key === 'start_date' || key === 'sent_date' || key == 'end_date' || key == 'invoice_date') {
            var c = value.split(' to ')
            if (c.length > 1) {
              myFilter.push(this.normalize(key) + ' from ' + c[0] + ' to ' + c[1])
              query = query + '&' + key.replace('.', '__') + '__gte=' + c[0]
              query = query + '&' + key.replace('.', '__') + '__lte=' + c[1]
            } else {
              myFilter.push(this.normalize(key) + ' from ' + c[0] + ' to ' + c[0])
              query = query + '&' + key.replace('.', '__') + '=' + c[0]
              query = query + '&' + key.replace('.', '__') + '__lte=' + c[0]
            }
          } else if (key === 'process_stage') {
            var result1 = value[0] === '' ? '0' : value[0]
            if (value.length > 1) {
              var result2 = value[1] === '' ? '100' : value[1]
              myFilter.push('Process stage from ' + result1 + ' to ' + result2)
              query = query + '&' + key.replace('.', '__') + '__gte=' + result1
              query = query + '&' + key.replace('.', '__') + '__lte=' + result2
            } else {
              myFilter.push('Process stage contains ' + result1)
              query = query + '&' + key.replace('.', '__') + '__icontains=' + result1
            }
          } else if (key === 'candidate') {
            myFilter.push(this.normalize(key) + ' contains ' + value)
            query = query + '&' + 'candidate.name'.replace('.', '__') + '__icontains=' + value
          } else {
            myFilter.push(this.normalize(key) + ' contains ' + value)
            query = query + '&' + key.replace('.', '__') + '__icontains=' + value
          }
        }
      }
      this.query = query
      this.myFilter = myFilter
      this.loadData(1, this.ordering, query)
      }, 500)
      
    },
    normalize (item) {
      switch(item) {
        case 'recruiter.name':
          return 'Recruiter'
        case 'manager.name':
          return 'Business/account manager'
        case 'candidate':
          return 'Candidate'
        case 'customer.name':
          return 'Client'
        case 'role':
          return 'Role'
        case 'sent_date':
          return 'Sent date'
        case 'days_in_process':
          return 'Days in process'
        case 'last_update':
          return 'Last update'
        case 'days_in_stage':
          return 'Days in stage'
        case 'status.description':
          return 'Status'
        case 'reason.description':
          return 'Reason'
        case 'disqualification_reason':
          return 'Disqualification reason'
        case 'current_stage.description':
          return 'Current stage'
        case 'next_stage.description':
          return 'Next stage'
        case 'process_stage':
          return 'Process stage'
        case 'start_date':
          return 'Start date'
        case 'end_date':
          return 'Project end date'
        case 'salary':
          return 'Salary'
        case 'department.description':
          return 'Type'
        case 'fee_rate':
          return 'Fee rate'
        case 'revenue':
          return 'Revenue'
        case 'note':
          return 'Note'
        case 'project_revenue':
          return 'Project revenue'
        case 'roi':
          return 'ROI'
        case 'roi_percentage':
          return '% ROI'
        case 'pricelist_roi':
          return 'Pricelist ROI'
        case 'pricelist_roi_percentage':
          return 'Pricelist % ROI'
        case 'invoice_number':
          return 'Invoice number'
        case 'invoice_date':
          return 'Invoice date'
        default:
          return item
      }
    },
    onPerPageChange () {

    },
    cleanZero (value) {
      return !Number.isNaN(Number(value.replace('.00', '').replace(',', ''))) && Number(value.replace('.00', '').replace(',', '')) !== 0 ? Number(value.replace('.00', '').replace(',', '')) : ''
    },
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split(" to ")
      let startDate = Date.parse(dateRange[0])
      let endDate = Date.parse(dateRange[1])
      if (isNaN(endDate)) {
        return Date.parse(data) === startDate
      }
      return Date.parse(data) >= startDate && Date.parse(data) <= endDate
    },
    numberFilter(data, filterString) {
      return (data = String(data).includes(filterString))
    },
    numberRangeFilter(data, filterString) {
      let start = filterString[0] === '' ? 0 : Number(filterString[0])
      let end = filterString[1] === '' ? 100 : Number(filterString[1])
      return (data = data >= start && data <= end)
    },
    processStageVariant (value, status) {
      value = Number(value)
      if (value === 100 && status == 'Hired') {
        return 'success'
      } else if (value === 100 && status == 'Internalized') {
        return 'success'
      } else if (value === 100 && status == 'Project end') {
        return 'success'
      } else if (value === 100 && status == 'No allocation') {
        return 'success'
      } else if (status == 'Disqualified') {
        return 'danger'
      } else if (value > 69) {
        return 'warning'
      } else {
        return 'primary'
      }
    },
    statusVariant (value) {
      var result = 'light-info'
      switch (value) {
        case 'In progress':
          result = 'light-warning'
          break
        case 'Hired':
          result = 'light-success'
          break
        case 'Disqualified':
          result = 'light-danger'
          break
        case 'Project end':
          result = 'project-end'
          break
        case 'Internalized':
          result = 'internalized'
          break
        case 'No allocation':
          result = 'no-allocation'
          break
        default:
          result = 'light-info'
      }
      return result
    },
    hasPermission (permission) {
      for (let i = 0; i < permission.length; i++) {
        var obj = permission[i].split('.')
        for (let x = 0; x < store.state.portal.profile.user_permissions.length; x++) {
          if (obj[1] === store.state.portal.profile.user_permissions[x].codename && obj[0] === store.state.portal.profile.user_permissions[x].content_type.app_label) {
            return false
          }
        }
        for (let x = 0; x < store.state.portal.profile.groups.length; x++) {
          for (let z = 0; z < store.state.portal.profile.groups[x].permissions.length; z++) {
            if (obj[1] === store.state.portal.profile.groups[x].permissions[z].codename && obj[0] === store.state.portal.profile.groups[x].permissions[z].content_type.app_label) {
              return false
            }
          }
        }
      }
      return true
    },
    loadData (page=1, ordering='', query='', search='', download=false, hide_rhinos=false) {
      this.$loading(true)
      Report.getFinance(page, ordering, query, search, download, this.checked).then(response => {
        if (download === true) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'finance_report.xlsx');
          document.body.appendChild(link);
          link.click()
          return
        }
        var items = []
        response.data.results.forEach(element => {
          var item = element
          if (!element.recruiter) {
            item.recruiter = {}
          }
          items.push(item)
        })
        this.rows = items
        this.total = response.data.count
        if (this.hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter', 'cockpit.is_ihta_recruiter'])) {
          Report.totalRevenue(page, ordering, query, search, this.checked).then(response => {
            this.totalRevenue = response.data.total
            this.totalPr = response.data.total_pr
            this.totalRoi = response.data.total_roi
            this.totalPricelist = response.data.total_pricelist
            this.$loading(false)
          })
          setTimeout(() => {
            this.ShowReportId()
          }, 200)
        }
        window.scrollTo(0,0)
      }).catch(error => {
        if (error.response.status === 403) {
          this.$router.push('/not-authorized')
        }
        this.$loading(false)
      })
    },
    getRecruiters () {
      this.$loading(true)
      Report.getRecruiters().then(response => {
        this.recruiters = response.data.results.filter(element => {
          var item = {}
          item = element
          item.title = element.name
          return item
        })
      })
    },
    getManagers () {
      this.$loading(true)
      Report.getManagers().then(response => {
        this.managers = response.data.results.filter(element => {
          var item = {}
          item = element
          item.title = element.name
          return item
        })
      })
    },
    getStatus () {
      this.$loading(true)
      Report.getStatus().then(response => {
        this.statusOptions = response.data.results
      })
    },
    getReasons () {
      this.$loading(true)
      Report.getReasons().then(response => {
        this.reasonOptions = response.data.results
      })
    },
    getDepartments () {
      this.$loading(true)
      Report.getDepartments().then(response => {
        this.departments = response.data.results
      })
    },
    clearData () {
      this.$router.go()
    },
    ShowReportId () {
      if (this.$route.query.rp_id) {
        Report.getAFinanceReport(this.$route.query.rp_id).then(response => {
          this.editNote(response.data)
          this.$bvModal.show('edit-note-btn')
          this.$router.replace({
            query: {}
          })
        })
        
      }
    },
    getColumns (loading=true) {
      if (loading) {
        this.$loading(true)
      }
      var columns = this.selectedColumns
      if (this.$store.state.portal.financeColumns.length > 0) {
        columns = this.$store.state.portal.financeColumns
      } else {
        Report.getFinanceColumns().then(response => {
          if (response.data.results.length > 0) {
            columns = response.data.results[0].data
            this.$store.commit('portal/setFinanceColumns', columns)
          } else {
            this.$store.commit('portal/setFinanceColumns', columns)
          }
        })
      }
      for (let x = 0; x < this.columns.length; x++) {
        if (columns.includes(this.columns[x].field)) {
          this.columns[x].hidden = true
        } else {
          this.columns[x].hidden = false
        }
      }
      this.selectedColumns = columns
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  created () {
    if (!this.hasPermission(['finance.is_damia_recruiter'])) {
      this.selectedColumns.push('reason.description')
      this.selectedColumns.push('department.description')
      this.selectedColumns.push('revenue')
      this.selectedColumns.push('last_update')
      this.selectedColumns.push('next_stage.description')
      this.selectedColumns.push('fee_rate')
      this.selectedColumns.push('end_date')
      this.selectedColumns.push('project_revenue')
      this.selectedColumns.push('roi')
      this.selectedColumns.push('roi_percentage')
      this.selectedColumns.push('pricelist_roi')
      this.selectedColumns.push('pricelist_roi_percentage')
      this.selectedColumns.push('invoice_number')
      this.selectedColumns.push('invoice_date')
      this.selectedColumns.push('note')
      for (let x = 0; x < this.myColumns.length; x++) {
        if (
          this.myColumns[x].value === 'reason.description' ||
          this.myColumns[x].value === 'revenue' ||
          this.myColumns[x].value === 'department.description' ||
          this.myColumns[x].value === 'last_update' ||
          this.myColumns[x].value === 'next_stage.description' ||
          this.myColumns[x].value === 'fee_rate' ||
          this.myColumns[x].value === 'end_date' ||
          this.myColumns[x].value === 'project_revenue' ||
          this.myColumns[x].value === 'roi' ||
          this.myColumns[x].value === 'roi_percentage' ||
          this.myColumns[x].value === 'pricelist_roi' ||
          this.myColumns[x].value === 'pricelist_roi_percentage' ||
          this.myColumns[x].value === 'invoice_number' ||
          this.myColumns[x].value === 'invoice_date' ||
          this.myColumns[x].value === 'note' 
        ) {
          this.myColumns[x].disabled = true
        }
      }
    }
    if (!this.hasPermission(['cockpit.is_ihta_recruiter'])) {
      this.selectedColumns.push('reason.description')
      this.selectedColumns.push('department.description')
      this.selectedColumns.push('revenue')
      this.selectedColumns.push('last_update')
      this.selectedColumns.push('next_stage.description')
      this.selectedColumns.push('fee_rate')
      this.selectedColumns.push('end_date')
      this.selectedColumns.push('project_revenue')
      this.selectedColumns.push('roi')
      this.selectedColumns.push('roi_percentage')
      this.selectedColumns.push('pricelist_roi')
      this.selectedColumns.push('pricelist_roi_percentage')
      this.selectedColumns.push('invoice_number')
      this.selectedColumns.push('invoice_date')
      this.selectedColumns.push('note')
      for (let x = 0; x < this.myColumns.length; x++) {
        if (
          this.myColumns[x].value === 'reason.description' ||
          this.myColumns[x].value === 'revenue' ||
          this.myColumns[x].value === 'department.description' ||
          this.myColumns[x].value === 'last_update' ||
          this.myColumns[x].value === 'next_stage.description' ||
          this.myColumns[x].value === 'fee_rate' ||
          this.myColumns[x].value === 'end_date' ||
          this.myColumns[x].value === 'project_revenue' ||
          this.myColumns[x].value === 'roi' ||
          this.myColumns[x].value === 'roi_percentage' ||
          this.myColumns[x].value === 'pricelist_roi' ||
          this.myColumns[x].value === 'pricelist_roi_percentage' ||
          this.myColumns[x].value === 'invoice_number' ||
          this.myColumns[x].value === 'invoice_date' ||
          this.myColumns[x].value === 'note' 
        ) {
          this.myColumns[x].disabled = true
        }
      }
    }
    if (!this.hasPermission(['finance.is_meta_recruiter'])) {
      this.selectedColumns.push('department.description')
      this.selectedColumns.push('revenue')
      this.selectedColumns.push('fee_rate')
      this.selectedColumns.push('end_date')
      this.selectedColumns.push('project_revenue')
      this.selectedColumns.push('roi')
      this.selectedColumns.push('roi_percentage')
      this.selectedColumns.push('pricelist_roi')
      this.selectedColumns.push('pricelist_roi_percentage')
      this.selectedColumns.push('invoice_number')
      this.selectedColumns.push('invoice_date')
      this.selectedColumns.push('note')
      for (let x = 0; x < this.myColumns.length; x++) {
        if (
          this.myColumns[x].value === 'revenue' ||
          this.myColumns[x].value === 'department.description' ||
          this.myColumns[x].value === 'fee_rate' ||
          this.myColumns[x].value === 'end_date' ||
          this.myColumns[x].value === 'project_revenue' ||
          this.myColumns[x].value === 'roi' ||
          this.myColumns[x].value === 'roi_percentage' ||
          this.myColumns[x].value === 'pricelist_roi' ||
          this.myColumns[x].value === 'pricelist_roi_percentage' ||
          this.myColumns[x].value === 'invoice_number' ||
          this.myColumns[x].value === 'invoice_date' ||
          this.myColumns[x].value === 'note' 
        ) {
          this.myColumns[x].disabled = true
        }
      }
    }
    this.getColumns(false)
    this.getUsers()
    this.getDepartments()
    this.getRecruiters()
    this.getManagers()
    this.getStatus()
    this.getReasons()
    this.loadData()
  }
}
</script>
